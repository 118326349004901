import styles from "../PeopleFilter.module.scss";
import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
import { workLocation as workLocationTypes } from "components/Utils/General";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import DayPicker from "components/Utils/SubComs/DayPicker/DayPicker";
import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";

const FreelanceFilters = ({
	onWorkLocation,
	workLocation,
	expLvl,
	onExpLvl,
	onCalendarAvailability,
	calendarAvailability,
	onStartDate,
	onEndDate,
	startDate,
	endDate,
}) => {
	return (
		<div className={styles.separator_container}>
			<div className={styles.container}>
				<div className={styles.agency_wrapper}>
					<div className={styles.agency_checkbox}>
						{/*  //TODO >>  use <Switch /> component instead of checkbox */}
						<Checkbox
							className={styles.checkbox}
							checked={calendarAvailability}
							onChange={onCalendarAvailability}
							name={"Calendar Availability"}
						/>
						<span onClick={onCalendarAvailability}>
							Show Jobs as per calendar availability
						</span>
					</div>
				</div>

				<div className={styles.datePickerContainer}>
					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>From</label>
							<DayPicker
								className={`form-start-date`}
								onChange={onStartDate}
								value={startDate && new Date(startDate)}
								isClearable={true}
							/>
						</div>
						<div className={styles.datePickerItem}>
							<label>To</label>
							<DayPicker
								className={`form-end-date`}
								onChange={onEndDate}
								value={endDate && new Date(endDate)}
								isClearable={true}
							/>
						</div>
					</div>
				</div>

				<MultiCheckbox
					title={"Working Location"}
					type="radio"
					options={workLocationTypes}
					onSelect={onWorkLocation}
					selected={workLocation}
					perRow="3, 100px"
					customStyle={styles.multicheckbox_container}
				/>

				<InputWrapper
					label="Experience Level"
					component={
						<TextInput
							value={expLvl}
							placeholder="Enter number of years"
							onChange={onExpLvl}
							type="number"
						/>
					}
				/>
			</div>
		</div>
	);
};

export default FreelanceFilters;
