import styles from "./SeeMore.module.css";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";

const SeeMore = ({ text = "More", onClick, className, color, style }) => {
	return (
		<button
			className={`${styles.see_more} ${className}`}
			style={style}
			onClick={onClick}
			type={"button"}
		>
			<label style={{ color }}>{text}</label>
			<ForwardIcon fill={color ? color : "#686e76"} className={styles.forward_icon} />
		</button>
	);
};

export default SeeMore;
