import Switch from "react-switch";
import styles from "./Filters.module.css";
import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { workLocation as workLocationTypes } from "../../../Utils/General";
import { useDispatch, useSelector } from "react-redux";
import { updateJobsFilters } from "store/slices/filters";
import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce";

const FreelanceFilter = () =>
	//     {
	//     onCalendarChange,
	//     calendarAvailability,
	//     onStartDateChange,
	//     onEndDateChange,
	//     startDate,
	//     endDate,
	//     onWorkLocationChange,
	//     workLocation,
	//     onExperienceChange,
	//     experienceLevel,
	// }
	{
		const dispatch = useDispatch();

		const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

		const storedJobsFilters = {
			...jobsFiltersObject,
		};

		const {
			freelanceRequest: {
				workingLocation: freelanceWorkingLocation,
				experience: storedFreelanceExp = "",
				showBasedOnCalendar,
				startDate,
				endDate,
			},
		} = storedJobsFilters || {};

		//! debouncing freelance exp level ==========================

		const [freelanceExpLvl, setFreelanceExpLvl] = useState(storedFreelanceExp);
		const debouncedFreelanceExpLvl = useDebounce(freelanceExpLvl);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					freelanceRequest: {
						...storedJobsFilters.freelanceRequest,
						experience: debouncedFreelanceExpLvl,
					},
				})
			);
		}, [debouncedFreelanceExpLvl]);

		return (
			<div className={styles.container}>
				<div className={styles.section}>
					<div className={styles.switch_wrapper}>
						<Switch
							checked={showBasedOnCalendar}
							onColor="#6cc5d1"
							offColor="#a6b1bc"
							handleDiameter={18}
							width={39}
							height={24}
							checkedIcon={false}
							uncheckedIcon={false}
							activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
							onChange={() => {
								dispatch(
									updateJobsFilters({
										freelanceRequest: {
											...storedJobsFilters.freelanceRequest,
											showBasedOnCalendar: !showBasedOnCalendar,
										},
									})
								);
							}}
						/>
						<span>Show jobs as per Calendar availability</span>
					</div>
				</div>

				{!showBasedOnCalendar && (
					<div className={`${styles.section} ${styles.inline_input}`}>
						<div className={styles.item}>
							<label>Start Date</label>
							<DayPicker
								className={"form-start-date"}
								onChange={(startDate) =>
									dispatch(
										updateJobsFilters({
											freelanceRequest: {
												...storedJobsFilters.freelanceRequest,
												startDate: startDate?.getTime() || null,
											},
										})
									)
								}
								value={startDate}
								isClearable={true}
							/>
						</div>
						<div className={styles.item}>
							<label>End Date</label>
							<DayPicker
								className={"form-end-date"}
								onChange={(endDate) => {
									dispatch(
										updateJobsFilters({
											freelanceRequest: {
												...storedJobsFilters.freelanceRequest,
												endDate: endDate?.getTime() || null,
											},
										})
									);
								}}
								value={endDate}
								isClearable={true}
							/>
						</div>
					</div>
				)}

				<div className={styles.section}>
					<MultiCheckbox
						title={"Working Location"}
						options={workLocationTypes}
						onSelect={(workLocation) =>
							dispatch(
								updateJobsFilters({
									freelanceRequest: {
										...storedJobsFilters.freelanceRequest,
										workingLocation: workLocation[0].value,
									},
								})
							)
						}
						selected={[
							workLocationTypes.find(
								(item) => item.value === freelanceWorkingLocation
							),
						]}
						type={"radio"}
						customStyle={styles.checkbox_labels}
					/>
				</div>

				<div className={styles.section}>
					<div className={styles.form_input_wrapper}>
						<label>Experience Level</label>
						<TextInput
							onChange={({ target }) => setFreelanceExpLvl(target.value)}
							value={freelanceExpLvl}
							type="number"
							placeholder="Enter number of years"
						/>
					</div>
				</div>
			</div>
		);
	};

export default FreelanceFilter;
