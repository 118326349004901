import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

export default function useInfiniteScroll({
	queryKey,
	queryFn,
	pageSize = 20,
	enabled = true,
	queryOptions,
}) {
	const [items, setItems] = useState([]);

	const { ref: viewRef, inView } = useInView({
		// threshold: 0.1, // Trigger when 10% of the element is visible
		triggerOnce: true,
	});

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
		isFetching,
		isError,
		isFetched,
		refetch,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey: queryKey,
		queryFn: queryFn,
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.length === pageSize ? allPages.length : undefined;
		},
		enabled,
		...queryOptions,
	});

	useEffect(() => {
		if (data) {
			setItems(data.pages.flat());
		}
	}, [data]);

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	const loadMore = () => {
		if (hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	};

	return {
		data,
		items,
		isLoading,
		isFetching,
		isFetchingNextPage,
		isError,
		loadMore,
		viewRef,
		hasNextPage,
		isFetched,
		refetch,
		fetchStatus,
	};
}
