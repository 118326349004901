import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	agencyFilters: {
		locationIds: [],
		types: [],
	},
	talentFilters: {},
	peopleFilters: {
		friends: false,
		inMyCircle: false,
		inTheirCircle: false,
		languages: [],
		roles: [],
		skills: [],
		software: [],
	},
	companiesFilters: {
		inMyCircle: false,
		inTheirCircle: false,
		agency: false,
		industryIds: [],
		specialties: [],
	},
	postsFilters: {
		friends: false,
		inMyCircle: false,
		inTheirCircle: false,
		includeMedia: false,
		fromDate: null,
		toDate: null,
	},
	jobsFilters: {
		jobType: 0,
		time: "NONE",
		skillIds: [],
		frequency: "MONTHLY",
		compensation: "",
		currencyId: "",
		castingCallRequest: {
			date: null,
			ethnicityId: "",
			ethnicityObject: null,
			gender: 2,
			languageIds: [],
			maxAge: "",
			minAge: "",
		},
		freelanceRequest: {
			endDate: null,
			experience: "",
			showBasedOnCalendar: false,
			startDate: null,
			workingLocation: "OFFICE",
		},
		jobRequest: {
			experience: "",
			longTermJobTypeId: [],
			workingLocation: "OFFICE",
		},
	},
	productsFilters: {
		colors: [],
		friends: false,
		inMyCircle: false,
		inTheirCircle: false,
		materials: [],
		maxHeight: "",
		maxLength: "",
		maxWeight: "",
		maxWidth: "",
		minHeight: "",
		minLength: "",
		minWeight: "",
		minWidth: "",
		onlyUncategorizedProducts: false,
		ownerIds: [],
		serviceTags: [],
		widthUnit: "METER",
		heightUnit: "METER",
		lengthUnit: "METER",
		weightUnit: "GRAM",
	},
	servicesFilters: {
		gaawkCategories: [],
		friends: false,
		inMyCircle: false,
		inTheirCircle: false,
		includeMedia: false,
		includeProducts: false,
	},
};

const slice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		updateAgencyFilters: (state, action) => {
			state.agencyFilters = action.payload;
		},
		resetAgencyFilters: (state) => {
			state.agencyFilters = initialState.agencyFilters;
		},
		updateTalentFilters: (state, action) => {
			state.talentFilters = { ...state.talentFilters, ...action.payload };
		},
		resetTalentFilters: (state) => {
			state.talentFilters = initialState.talentFilters;
		},
		updatePeopleFilters: (state, action) => {
			state.peopleFilters = { ...state.peopleFilters, ...action.payload };
		},
		resetPeopleFilters: (state) => {
			state.peopleFilters = initialState.peopleFilters;
		},
		updateCompaniesFilters: (state, action) => {
			state.companiesFilters = {
				...state.companiesFilters,
				...action.payload,
			};
		},
		resetCompaniesFilters: (state) => {
			state.companiesFilters = initialState.companiesFilters;
		},
		updatePostsFilters: (state, action) => {
			state.postsFilters = {
				...state.postsFilters,
				...action.payload,
			};
		},
		resetPostsFilters: (state) => {
			state.postsFilters = initialState.postsFilters;
		},
		updateJobsFilters: (state, action) => {
			state.jobsFilters = {
				...state.jobsFilters,
				...action.payload,
			};
		},
		resetJobsFilters: (state, action) => {
			state.jobsFilters = { ...initialState.jobsFilters, jobType: action.payload || 0 };
		},
		updateProductsFilters: (state, action) => {
			state.productsFilters = {
				...state.productsFilters,
				...action.payload,
			};
		},
		resetProductsFilters: (state) => {
			state.productsFilters = initialState.productsFilters;
		},
		updateServicesFilters: (state, action) => {
			state.servicesFilters = {
				...state.servicesFilters,
				...action.payload,
			};
		},
		resetServicesFilters: (state) => {
			state.servicesFilters = initialState.servicesFilters;
		},
		resetAllFilters: () => initialState,
	},
});

export const {
	updateAgencyFilters,
	resetAgencyFilters,
	updateTalentFilters,
	resetTalentFilters,
	updatePeopleFilters,
	resetPeopleFilters,
	updateCompaniesFilters,
	resetCompaniesFilters,
	updatePostsFilters,
	resetPostsFilters,
	updateJobsFilters,
	resetJobsFilters,
	updateProductsFilters,
	resetProductsFilters,
	updateServicesFilters,
	resetServicesFilters,
	resetAllFilters,
} = slice.actions;

export default slice.reducer;
