import { useEffect, useState } from "react";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { workLocation as workLocationTypes, employementTypes } from "../../../Utils/General";
import styles from "./Filters.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateJobsFilters } from "store/slices/filters";
import useDebounce from "hooks/useDebounce";

const JobsFilter = () =>
	//     {
	//     onJobTypeChange,
	//     jobType,
	//     onWorkLocationChange,
	//     workLocation,
	//     onExperienceChange,
	//     experienceLevel,
	// }
	{
		const dispatch = useDispatch();

		const excludedEmployement = [
			"SELF_EMPLOYED",
			"FREELANCE",
			"APPRENTICESHIP",
			"CASTING_CALLS",
		];
		const filteredEmployement = employementTypes.filter(
			(item) => !excludedEmployement.includes(item.value)
		);

		const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

		const storedJobsFilters = {
			...jobsFiltersObject,
		};

		const {
			jobRequest: { longTermJobTypeId, workingLocation, experience: storedExpLongTermJob },
		} = storedJobsFilters || {};

		//! debouncing long_term exp level ==========================

		const [expLvl, setExpLvl] = useState(storedExpLongTermJob);
		const debouncedExpLvl = useDebounce(expLvl);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					jobRequest: {
						...storedJobsFilters.jobRequest,
						experience: debouncedExpLvl,
					},
				})
			);
		}, [debouncedExpLvl]);

		// !==============

		return (
			<div className={styles.container}>
				<div className={styles.section}>
					<MultiCheckbox
						title={"Job Type"}
						options={filteredEmployement}
						onSelect={(employementType) =>
							dispatch(
								updateJobsFilters({
									jobRequest: {
										...storedJobsFilters.jobRequest,
										longTermJobTypeId: employementType,
									},
								})
							)
						}
						selected={longTermJobTypeId}
					/>
				</div>

				<div className={styles.section}>
					<MultiCheckbox
						title={"Working Location"}
						options={workLocationTypes}
						onSelect={(workLocation) =>
							dispatch(
								updateJobsFilters({
									jobRequest: {
										...storedJobsFilters.jobRequest,
										workingLocation: workLocation[0].value,
									},
								})
							)
						}
						selected={[
							workLocationTypes.find((item) => item.value === workingLocation),
						]}
						type={"radio"}
						customStyle={styles.checkbox_labels}
					/>
				</div>

				<div className={styles.section}>
					<div className={styles.form_input_wrapper}>
						<label>Experience Level</label>
						<TextInput
							onChange={({ target }) => setExpLvl(target.value)}
							value={expLvl}
							type="number"
							placeholder="Enter number of years"
						/>
					</div>
				</div>
			</div>
		);
	};

export default JobsFilter;
