import jobApi from "api/job";
import EmployerLanding from "components/Jobs/EmployerLanding/EmployerLanding";
import JobsBanner from "components/Jobs/Landing/JobsBanner";
import NewJobDetails from "components/Jobs/NewJobDetails/NewJobDetails";
import ProjectAll from "components/Jobs/ProjectAll/ProjectAll";
import ProjectDetails from "components/Jobs/ProjectDetails/ProjectDetails";
import Results from "components/Jobs/Results/Results";
import { featuresSection } from "components/Utils/General";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import useGetQueryParam from "hooks/useGetQueryParam";
import { ReactComponent as BookmarkIcon } from "images/bookmark.svg";
import { ReactComponent as JobIcon } from "images/job.svg";
import optionIcon from "images/three-dots-white-5-x-20.svg";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdvancedSearch from "../../components/Jobs/AdvancedSearch/AdvancedSearch";
import AgencyFilterView from "../../components/Jobs/Agency/AgencyFilterView";
import ApplicantDetails from "../../components/Jobs/Applicants/ApplicantDetails";
import JobApplicants from "../../components/Jobs/Applicants/JobApplicants";
import JobApply from "../../components/Jobs/Apply/JobApply";
import JobConfirmation from "../../components/Jobs/Confirmation/JobConfirmation";
import JobEntry from "../../components/Jobs/JobEntry/JobEntry";
import Jobs from "../../components/Jobs/Landing/Jobs";
import ProjectEntry from "../../components/Jobs/ProjectEntry/ProjectEntry";
import SavedAppliedJobs from "../../components/Jobs/SavedAppliedJobs/SavedAppliedJobs";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import Popover from "../../components/Utils/Popover/Popover";
import useCompanyId from "../../hooks/useCompanyId";
import TalentFilterView from "../talents/TalentFilterView";
import styles from "./JobsView.module.scss";
import { useDispatch } from "react-redux";
import { resetJobsFilters } from "store/slices/filters";

const JobsView = () => {
	const navigate = useNavigate();
	const { pathname, state } = useLocation();

	const popoverRef = useRef(null);
	const companyId = useCompanyId();
	// const defaultPageRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			//CLEARS JOBS SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetJobsFilters());
		};
	}, []);

	const { jobId, projectId, applicationId } = useParams();

	const [countryFlag, setCountryFlag] = useState();
	const jobsRef = useRef(null);

	let component;
	let bannerComponent;
	let headerBtn;
	let headerTitle;
	let directRender = false; //* this allows to render the component directly instead of using the <DefaultPage /> arch component
	let defaultBgColor = "#FFF";

	const { paramValue: queryParam } = useGetQueryParam("q");

	switch (pathname) {
		case "/jobs/results":
			// component = <Results searchProps={state?.body} />;
			component = (
				<Results
					// body={state?.body}
					// searchProps={state?.searchProps}
					ref={jobsRef}
					onFlagChange={setCountryFlag}
				/>
			);
			headerTitle = queryParam;

			headerBtn = (
				<button onClick={() => jobsRef.current.showModal()}>
					<img src={countryFlag} alt="" />
				</button>
			);
			//TODO >> replace headerBtn by the flag (useUserCoordinates)
			// headerBtn = (
			// 	<button
			// 		onClick={() =>
			// 			navigate("/jobs/filter", {
			// 				state: state.searchProps,
			// 			})
			// 		}
			// 	>
			// 		<img
			// 			src={filterIcon}
			// 			className={styles.filter_icon}
			// 			alt=""
			// 		/>
			// 	</button>
			// );
			break;

		case "/jobs/agency":
			component = <AgencyFilterView />;
			directRender = true;
			break;

		case "/jobs/talents":
			component = <TalentFilterView isGeneralSearch={true} />;
			directRender = true;
			break;

		case "/jobs/filter":
			component = (
				<AdvancedSearch
					filterMode={true}
					// data={state}
				/>
			);
			break;

		case "/jobs/advanced":
			component = <AdvancedSearch />;
			break;

		case "/jobs/applied":
			component = (
				<SavedAppliedJobs
					endpoint={jobApi.getAppliedJobs}
					queryKey={jobsKeys.applied}
					infoText="The jobs you’ve applied to will appear here for 45 days.
                    After-which they will be permanently deleted, unless you save
                    them to your saved jobs."
				/>
			);
			break;

		case "/jobs/saved":
			component = (
				<SavedAppliedJobs
					endpoint={jobApi.getSavedJobs}
					queryKey={jobsKeys.saved}
					infoText="Your saved jobs will appear here. They will stay here until you
                    un-save them. Even if a job is closed or no longer available."
				/>
			);
			break;

		case "/jobs/create":
			headerTitle = "Create Job";

			component = <JobEntry jobType={state?.jobType} project={state?.project} />;
			break;

		case `/jobs/${jobId}/edit`:
			component = <JobEntry />;
			break;

		case `/jobs/${jobId}`:
			component = <NewJobDetails />;
			directRender = true;

			break;

		case `/jobs/${jobId}/apply`:
			component = <JobApply data={state} />;
			directRender = true;
			break;

		case `/jobs/${jobId}/applicants`:
			component = <JobApplicants />;
			headerTitle = "Applicants";
			break;

		case `/jobs/${jobId}/applicants/${applicationId}`:
			component = <ApplicantDetails />;
			headerTitle = "Review Applicant";

			break;

		case `/jobs/${jobId}/confirmation`:
			component = <JobConfirmation data={state} />;
			defaultBgColor = undefined;
			break;

		case `/jobs/project/${projectId}/edit`:
			headerTitle = "Update Project";
			component = <ProjectEntry />;
			break;

		case `/jobs/project/${projectId}`:
			headerTitle = "Project";
			directRender = true;
			component = <ProjectDetails />;
			break;

		case "/jobs":
			dispatch(resetJobsFilters());
			headerTitle = "Jobs";
			if (companyId) {
				component = <EmployerLanding />;
			} else {
				component = <Jobs />;
				bannerComponent = <JobsBanner />;
				headerBtn = (
					<Popover
						ref={popoverRef}
						render={
							<div className="popover_container">
								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										navigate("/jobs/saved");
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<BookmarkIcon />
									</div>
									<span>Saved Jobs</span>
								</button>

								<button
									className={`popover_item ${styles.add_gap}`}
									onClick={() => {
										navigate("/jobs/applied");
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<JobIcon width={16} fill={"#000"} />
									</div>
									<span>Jobs you applied to</span>
								</button>
							</div>
						}
					>
						<button className={styles.option_icon}>
							<img src={optionIcon} alt="options" />
						</button>
					</Popover>
				);
			}
			break;

		case "/jobs/project/add":
			headerTitle = "Create Project";
			component = <ProjectEntry />;
			break;

		case "/jobs/projects":
			headerTitle = "Manage Projects";
			component = <ProjectAll />;
			break;

		default:
			break;
	}

	return (
		<>
			{directRender ? (
				component
			) : (
				<DefaultPage
					headerRightContent={
						<>
							<div className={styles.header}>
								<BackButton onBack={() => navigate(-1)} />
								{headerTitle}
							</div>
							{headerBtn}
						</>
					}
					bannerComponent={bannerComponent}
					defaultHeader={false}
					leftSideChildren={<FeaturesSidebar active={featuresSection.jobs} />}
					rightSideChildren={component}
					rightSideContainerColor={defaultBgColor}
				/>
			)}
		</>
	);
};

export default JobsView;
