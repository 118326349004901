import { useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import CityModal from "components/Boards/BoardsComponents/CityModal";
import { formatFiltersValues, pluralize } from "components/Utils/General";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import useUserLocation from "hooks/useUserLocation";
import filterIcon from "images/filter-icon-white.svg";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { searchKeys } from "queryKeys/search-key-factory";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NewJobCard from "../JobsComponents/NewJobCard/NewJobCard";
import styles from "./Results.module.scss";
import routes from "components/Routing/routing-keys";
// import CityModal from "components/Boards/BoardsComponents/CityModal";

const PAGE_SIZE = 40;

const Results = forwardRef(({ onFlagChange }, ref) => {
	const queryClient = useQueryClient();

	const searchJobsApi = useApi(jobApi.search, true, true);

	const navigate = useNavigate();

	//! ========= GETTING USER COORDINATES ============

	const [showModal, setShowModal] = useState(false);

	useImperativeHandle(ref, () => ({
		showModal() {
			setShowModal(true);
		},
	}));

	const { userCoordinates, handleSelectedCity } = useUserLocation(onFlagChange);

	// ! ==================== FETCH SEARCHED JOB ==========================

	const { paramValue: queryParam } = useGetQueryParam("q");

	const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

	const storedJobsFilters = {
		...jobsFiltersObject,
		q: queryParam || "",
		locationId: userCoordinates.id,
	};

	const fetchJobs = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchJobsApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		data: dataJobs,
		items,
		// isFetching,
		isFetchingNextPage,
		viewRef,
		isLoading,
	} = useInfiniteScroll({
		queryKey: searchKeys.jobs(storedJobsFilters),
		queryFn: fetchJobs,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);
	const count = dataJobs?.pages?.[0]?.count;
	const hasResults = count > 0;

	const jobsList = useMemo(
		() =>
			data?.map((job, index) => {
				const isLastItem = index === data.length - 1;
				return (
					<div key={job.uuid} ref={isLastItem ? viewRef : null}>
						<NewJobCard
							data={job}
							onToggleSave={() =>
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
									isReco: false,
								})
							}
						/>
						{isLastItem && isFetchingNextPage && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</div>
				);
			}),
		[data, viewRef]
	);

	// !===== get recommended jobs ======

	const getRecommendedJobsApi = useApi(jobApi.getRecommendedJobs, true, true);

	const fetchRecommendedJobs = async ({ pageParam = 0, queryKey }) => {
		const [_, __, ___, userCoordinates] = queryKey;
		const response = await getRecommendedJobsApi.request(
			pageParam,
			PAGE_SIZE,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		data: dataReco,
		items: recoJobs,
		viewRef: recoViewRef,
		isFetchingNextPage: isFetchingNextPageReco,
		isLoading: isLoadingReco,
		// isFetching: isFetchingReco,
		fetchStatus: fetchStatusReco,
	} = useInfiniteScroll({
		queryKey: jobsKeys.recommended(userCoordinates),
		queryFn: fetchRecommendedJobs,
		pageSize: PAGE_SIZE,
		enabled: !hasResults && !isLoading,
	});

	const hasReco = dataReco?.pages?.[0]?.length > 0;

	const recoList = useMemo(
		() =>
			recoJobs?.map((job, index) => {
				const isLastItem = index === recoJobs.length - 1;
				return (
					<div key={job.uuid} ref={isLastItem ? recoViewRef : null}>
						<NewJobCard
							data={job}
							onToggleSave={() =>
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
									isReco: true,
								})
							}
						/>
						{isLastItem && isFetchingNextPageReco && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</div>
				);
			}),
		[recoJobs, viewRef]
	);

	// ! ================================

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (_, variables) => {
		const { jobId, isReco } = variables;
		if (isReco) {
			queryClient.setQueryData(jobsKeys.recommended(userCoordinates), (oldData) => {
				return {
					...oldData,
					pages: oldData.pages.map((page) =>
						page.map((job) =>
							job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
						)
					),
				};
			});
		} else {
			queryClient.setQueryData(searchKeys.jobs(storedJobsFilters), (oldData) => {
				return {
					...oldData,
					pages: oldData.pages.map((page) => ({
						count: page.count,
						list: page.list.map((job) =>
							job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
						),
					})),
				};
			});
		}
	});

	const showJobs = hasResults && !isLoading;
	const showReco = !hasResults && !isLoading && hasReco && !isLoadingReco;

	if (isLoading || (isLoadingReco && fetchStatusReco !== "idle"))
		return (
			<div className={styles.loader_container}>
				<div>
					<JobLoader />
				</div>
				<div>
					<JobLoader />
				</div>
				<div>
					<JobLoader />
				</div>
			</div>
		);

	return (
		<>
			<NoResults
				visible={!hasResults && !isLoading}
				isSearch={true}
				text={
					<>
						<span>
							Sorry! Your search {queryParam ? `'${queryParam}' ` : ""}didn't return
							any results.
						</span>{" "}
						<span>You can try some of our recommendations below.</span>
					</>
				}
			/>

			{showJobs && (
				<>
					<div className={styles.title_wrapper}>
						<h3>{`${count} ${pluralize(count, "job")} found`}</h3>

						<button
							onClick={
								() => navigate(routes.jobsFilters(queryParam))
								// navigate(`/jobs/filter${queryParam ? `?q=${queryParam}` : ""}`)
							}
						>
							<img src={filterIcon} alt="" />
						</button>
					</div>
					<div className={styles.container}>{jobsList}</div>
				</>
			)}

			{showReco && (
				<>
					<div className={styles.title_wrapper}>
						<h3>Recommended Jobs</h3>
					</div>

					<div className={styles.container}>{recoList}</div>
				</>
			)}

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
});

export default Results;
