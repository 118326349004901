import { useState } from "react";
import styles from "./JobsBanner.module.scss";
import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProfileSwitch from "components/Utils/SubComs/BurgerMenu/ProfileSwitch";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import useUserLocation from "hooks/useUserLocation";
import CityModal from "components/Boards/BoardsComponents/CityModal";
import routes from "components/Routing/routing-keys";
// import useJobInitialFilters from "./hooks/useJobInitialFilters"; //TODO >> TO BE DELETED ONCE FINISHED REFACTO

const JobsBanner = ({ onResizeWindow }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const [postJobModal, setPostJobModal] = useState(false);

	const onSearch = () => {
		navigate(routes.jobsResults(searchParams.get("q")));
	};

	const handleKeyUp = (e) => {
		const key = e.key || e.keyCode;
		if (key === "Enter" || key === 13) {
			onSearch();
		}
	};

	const [showLocationModal, setShowLocationModal] = useState(false);
	const { userCoordinates, handleSelectedCity } = useUserLocation();

	return (
		<>
			<div ref={(node) => onResizeWindow(node?.clientHeight)} className={styles.container}>
				<div className={styles.inner_wrapper}>
					<h1>FIND YOUR DREAM JOB</h1>

					<SearchInput
						onKeyUp={handleKeyUp}
						onChange={(e) => {
							setSearchParams(
								(prev) => {
									prev.set("q", e.target.value);
									return prev;
								},
								{ replace: true }
							);
						}}
						value={searchParams.get("q") || ""}
						placeholder={"Search by job title"}
						showIcons={true}
						onClearClicked={() => {
							setSearchParams(
								(prev) => {
									prev.set("q", "");
									return prev;
								},
								{ replace: true }
							);
						}}
					/>

					<div className={styles.advanced_container}>
						<button onClick={() => navigate(routes.jobsAdvanced)}>
							Advanced Search
						</button>

						<button
							className={styles.location_button}
							onClick={() => setShowLocationModal(true)}
						>
							{userCoordinates.name}
							<img src={userCoordinates.countryDTO.flagUrl} alt="" />
						</button>
					</div>

					<div className={styles.find_section}>
						<p>
							<span onClick={() => navigate(routes.jobsTalents)}>FIND TALENT</span>
						</p>
						<p>
							<span onClick={() => setPostJobModal(true)}>POST A JOB</span>
						</p>
						<p>
							<span onClick={() => navigate(routes.jobsAgency)}>FIND AN AGENCY</span>
						</p>
					</div>
				</div>
			</div>

			<GaawkModal
				show={postJobModal}
				handleClose={() => setPostJobModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Post a Job"}
				closeAlign={"right"}
				children={
					<>
						<div className={styles.job_modal_container}>
							In order to post a Job opening, you need to have company profile. If you
							already have one, please switch to your company profile and post a job.
							Otherwise, create a company profile.
						</div>

						<div className={styles.job_modal_container}>
							<ProfileSwitch isOpen={postJobModal} jobsModal={true} />
						</div>
					</>
				}
			/>

			{showLocationModal && (
				<CityModal
					show={showLocationModal}
					onClose={() => setShowLocationModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default JobsBanner;
