import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import styles from "./ManageModerators.module.scss";
import { useEffect, useMemo, useState } from "react";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import usePaginatedResource from "hooks/usePaginatedResource";
import { useInView } from "react-intersection-observer";
import useApi from "hooks/useApi";
import boardsApi from "api/boards";
import { boardsKeys } from "queryKeys/boards-key-factory";
import GroupMember from "components/Chat/GroupMember";
import { ReactComponent as RemoveIcon } from "images/remove.svg";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
// import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
// import useDebounce from "hooks/useDebounce";
// import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { ReactComponent as BoardIcon } from "images/board.svg";
import CtaButton from "components/Utils/Button/CtaButton";
import ManageFollowers from "./ManageFollowers";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";

const itemsPerPage = 20;

const ManageModerators = ({ show, onClose, boardId, canEdit = false }) => {
	const [step, setStep] = useState(1);

	const queryClient = useQueryClient();

	// !==================== REMOVE MODERATORS =========================
	const [showWarning, setShowWarning] = useState(false);
	const [activeModerator, setActiveModerator] = useState("");

	const {
		action: { mutate: removeModerator },
	} = useMutate(boardsApi.deleteBoardModerator, () => {
		queryClient.invalidateQueries(boardsKeys.moderators(boardId));
		queryClient.invalidateQueries(boardsKeys.detail(boardId));
		setShowWarning(false);
	});

	const handleOpenModal = (moderator) => {
		setActiveModerator(moderator);
		setShowWarning(true);
	};

	// !==================== GET BOARD MODERATORS =========================

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getBoardModeratorsApi = useApi(boardsApi.getBoardModerators, true, true);

	const getModerators = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, boardId] = queryKey;

		const response = await getBoardModeratorsApi.request(boardId, pageParam, itemsPerPage);
		return response.data;
	};

	const { data, isFetching, hasNextPage, fetchNextPage, isLoading } = usePaginatedResource(
		boardsKeys.moderators(boardId),
		getModerators,
		itemsPerPage,
		show
	);

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	const moderatorsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page.map((item, i) => (
					<GroupMember
						// ref={viewRef}
						ref={page.length === i + 1 ? viewRef : null}
						customStyle={styles.contact_item}
						key={item.uuid}
						data={item}
						redirect={true}
						rightSideAction={
							canEdit && (
								<button onClick={() => handleOpenModal(item)}>
									<RemoveIcon />
								</button>
							)
						}
					/>
				))
			),
		[data, canEdit]
	);

	const hasResults = data?.pages?.[0]?.length > 0 && !isLoading;

	return (
		<>
			{step === 1 ? (
				<GaawkModal
					show={show}
					title={"Manage Moderators"}
					closeAlign={"right"}
					handleClose={onClose}
					defaultModal={false}
					showHeader={true}
					children={
						<div className={styles.container}>
							{/* {step === 1 ? (
    						<>

    						</>
    					) : (
    						<ManageFollowers
    							onAddModerator={() => setStep(1)}
    							boardId={boardId}
    							canAppointModerator={true}
    						/>
    					)} */}
							<h4>Moderators list</h4>

							<>
								{canEdit && hasResults && (
									<PrimaryButton
										text={"+ add more moderators"}
										className={styles.add_btn}
										onClick={() => setStep(2)}
									/>
								)}

								{hasResults && (
									<div className={styles.items_wrapper}>{moderatorsList}</div>
								)}

								<NoResults
									visible={!hasResults && !canEdit}
									text={"There are no moderators"}
								/>
							</>

							{/* {data?.pages?.[0]?.length > 0 &&
								!isLoading  && (
									<>
										<PrimaryButton
											text={"+ add more moderators"}
											className={styles.add_btn}
											onClick={() => setStep(2)}
										/>

										<div className={styles.items_wrapper}>
											{moderatorsList}
										</div>
									</>
								)} */}

							{!hasResults && canEdit && (
								<div className={styles.new_board_container}>
									<BoardIcon fill={"#000"} width={50} />
									<span>Feel free to add moderators here!</span>

									<CtaButton
										text={"+ add moderators"}
										onClick={() => setStep(2)}
									/>
								</div>
							)}

							<LoadingSpinner visible={isFetching} />

							<WarningModal
								show={showWarning}
								modalOnTop={true}
								headerText={`Are you sure you want to remove ${activeModerator.name} as a moderator in the board? They'll no longer be part of it.`}
								warningText="This action cannot be undone."
								cancelButtonText={"Cancel"}
								onCancelButtonClicked={() => setShowWarning(false)}
								submitButtonText={"remove"}
								onSubmitButtonClicked={() =>
									removeModerator({
										boardId,
										userId: activeModerator.uuid,
									})
								}
							/>
						</div>
					}
				/>
			) : (
				<ManageFollowers
					show={step === 2}
					onAddModerator={() => setStep(1)}
					boardId={boardId}
					appointModeratorEnabled={true}
					onBack={() => setStep(1)}
					onClose={() => {
						setStep(1);
						onClose();
					}}
					canEdit={canEdit}
				/>
			)}
		</>
	);
};

export default ManageModerators;
