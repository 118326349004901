import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import servicesApi from "api/services";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import GaawkRadioButton from "components/Utils/SubComs/GaawkRadioButton/GaawkRadioButton";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import deleteIcon from "images/icon-exit.svg";
import photoIconShort from "images/photo-icon-short.png";
import photoIconStandard from "images/photo-icon-standard.png";
import photoIconTall from "images/photo-icon-tall.png";
import previewHorizontal from "images/preview-horizontal.png";
import previewStacked from "images/preview-stacked.png";
import { profileKeys } from "queryKeys/profile-key-factory";
import { servicesKeys } from "queryKeys/services-key-factory";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { clear, setModal, setService, setStep } from "store/slices/service";
import ProgressIndicator from "../../ServicesComponent/ProgressIndicator";
import { thirdStep } from "../schema";
import styles from "../ServiceAdd.module.css";
// import useCurrentUser from "hooks/useCurrentUser";

const Stage3 = () => {
	// const { uuid: userId } = useCurrentUser();

	const dispatch = useDispatch();

	const [tempImage, setTempImage] = useState([]);
	const [tempImageMobile, setTempImageMobile] = useState([]);

	const { step1, step2, service } = useSelector((state) => state.service);

	const {
		action: { mutate: createUpdateService, isLoading },
	} = useMutate(service ? servicesApi.updateService : servicesApi.addService, (response) => {
		const { level, parents, uuid: serviceId } = response.data;

		if (service) {
			queryClient.invalidateQueries(servicesKeys.detail(serviceId));
		}

		queryClient.invalidateQueries(servicesKeys.lists()); //TRYING THIS INSTEAD OF INVALIDATING PER LEVEL

		if (level === 1) {
			// queryClient.invalidateQueries(
			// 	servicesKeys.serviceLvl(1, userId, "", false)
			// );
			queryClient.invalidateQueries(servicesKeys.searchList());
		}
		// else if (level === 2) {
		// 	queryClient.invalidateQueries(
		// 		servicesKeys.serviceLvl(2, userId, parents.p3.uuid, false)
		// 	);
		// } else if (level === 3) {
		// 	queryClient.invalidateQueries(
		// 		servicesKeys.serviceLvl(3, userId, parents.p2.uuid, false)
		// 	);
		// }
		dispatch(clear());
		dispatch(setModal(false));
		dispatch(setStep(1));
		dispatch(setService(undefined));
		toast.success(`${service ? "Edit saved" : "Service has been created"}`);

		queryClient.invalidateQueries(vaultKeys.storage());
		queryClient.invalidateQueries(profileKeys.details()); //  invalidate user profile to get the correct services/products count
	});

	const queryClient = useQueryClient();

	const {
		register,
		formState: {
			errors,
			// isDirty
		},
		handleSubmit,
		watch,
		control,
		// reset,
		setValue,
		// clearErrors,
	} = useForm({
		resolver: joiResolver(thirdStep),
		mode: "onChange",
		defaultValues: service
			? {
					headerStyle: service?.headerStyle,
					imageSize: service?.imageSize,
					sameImageMobile: !service?.mobileDisplay, //! >> mobile display is false all the time
					image: service?.image ? [service?.image?.file] : [],
					mobileImage: service?.mobileImage ? [service?.mobileImage?.file] : [],
			  }
			: {
					headerStyle: "STACKED",
					imageSize: "TALL",
					sameImageMobile: true,
			  },
	});

	const isSameImageMobile = watch("sameImageMobile");
	const headerStyleWatcher = watch("headerStyle");
	const imageWatcher = watch("image");
	const mobileImageWatcher = watch("mobileImage");
	const sizeWatcher = watch("imageSize");

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) setValue("image", []);

		didMountRef.current = true;
	}, [sizeWatcher]);

	const handleSave = async (data) => {
		const { level, parentId } = step1 || {};
		const {
			title,
			description,
			tag,
			gaawkLvl1,
			gaawkLvl2,
			gaawkLvl3,
			tagAlbumMedia,
			tagProducts,
			locationIds,
			worldWide,
		} = step2;

		const { headerStyle, image, imageSize, sameImageMobile, mobileImage } = data;

		const formData = new FormData();

		// //* STEP1
		if (service) {
			formData.append("level", service.level);
			if (service.level === 3) formData.append("parentId", service.parents.p2.uuid);
			if (service.level === 2) formData.append("parentId", service.parents.p3.uuid);
		} else {
			formData.append("level", level);
			formData.append("parentId", parentId);
		}

		// //* STEP2
		formData.append("title", title);
		formData.append("tag", tag);
		formData.append("description", description);
		formData.append("worldWide", worldWide);
		formData.append(
			"locationIds",
			locationIds.map((location) => location.value)
		);
		if (gaawkLvl3) {
			formData.append("gaawkServiceId", gaawkLvl3.value);
		} else if (gaawkLvl2) {
			formData.append("gaawkServiceId", gaawkLvl2.value);
		} else {
			formData.append("gaawkServiceId", gaawkLvl1.value);
		}
		formData.append("tagAlbumMedia", tagAlbumMedia);
		formData.append("tagProducts", tagProducts);

		// //* STEP3
		formData.append("headerStyle", headerStyle);
		if (headerStyle !== "TEXT") {
			formData.append("imageSize", imageSize);

			if (image[0] instanceof Blob) {
				formData.append("serviceImage", image[0], image[0].fileName);
			}

			formData.append("differentMobileImage", !sameImageMobile);

			if (!sameImageMobile) {
				if (mobileImage[0] instanceof Blob) {
					formData.append("serviceMobileImage", mobileImage[0], mobileImage[0].fileName);
				}
			}
		} else {
			formData.append("imageSize", "N_A");
		}

		// //! EDIT
		if (service) {
			formData.append("serviceId", service?.uuid);
		}

		createUpdateService(formData);

		// const response = service
		// 	? await updateServiceApi.request(formData)
		// 	: await addServiceApi.request(formData);

		// if (response.status === 201) {
		// 	if (service) {
		// 		queryClient.invalidateQueries(
		// 			servicesKeys.detail(service.uuid)
		// 		);
		// 	}

		// 	if (level === 1) {
		// 		queryClient.invalidateQueries(
		// 			servicesKeys.serviceLvl(1, userId)
		// 		);
		// 		// queryClient.invalidateQueries(servicesKeys.search(userId, ""));
		// 		queryClient.invalidateQueries(servicesKeys.searchList());
		// 	} else if (level === 2) {
		// 		queryClient.invalidateQueries(
		// 			servicesKeys.serviceLvl(2, userId, parentId)
		// 		);
		// 	} else if (level === 3) {
		// 		queryClient.invalidateQueries(
		// 			servicesKeys.serviceLvl(3, userId, parentId)
		// 		);
		// 	}
		// 	dispatch(clear());
		// 	dispatch(setModal(false));
		// 	dispatch(setStep(1));
		// 	dispatch(setService(undefined));
		// 	toast.success(
		// 		`${
		// 			service
		// 				? `Edit ${service?.name} is successfully done`
		// 				: `Service has been added`
		// 		}`
		// 	);
		// } else
		// 	toast.error(
		// 		`Failed to ${service ? "edit" : "add"} the introduction`
		// 	);
	};

	// !----- revoking image preview -----

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		if (
			imageWatcher?.[0] &&
			imageWatcher[0] instanceof Blob &&
			!(imageWatcher[0] instanceof File)
		) {
			setObjectUrls([URL.createObjectURL(imageWatcher[0])]);
		}

		// // Cleanup function
		return () => {
			cleanupObjectUrls();
		};
	}, [imageWatcher]);

	const {
		objectUrls: mobileObjectUrls,
		setObjectUrls: setMobileObjectUrls,
		cleanupObjectUrls: mobileCleanupObjectUrls,
	} = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		if (
			mobileImageWatcher?.[0] &&
			mobileImageWatcher[0] instanceof Blob &&
			!(mobileImageWatcher[0] instanceof File)
		) {
			setMobileObjectUrls([URL.createObjectURL(mobileImageWatcher[0])]);
		}

		// // Cleanup function
		return () => {
			mobileCleanupObjectUrls();
		};
	}, [mobileImageWatcher]);

	return (
		<form
			className={`${styles.form} ${styles.no_padding}`}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<div className={styles.section}>
				<div className={styles.progress_wrapper}>
					<ProgressIndicator step={3} />
				</div>

				<h3 className={styles.title}>Web display</h3>

				<span className={styles.info_text}>
					Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
					tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.
				</span>

				<div className={styles.mt_20}>
					<h3 className={styles.section_title}>
						Header Display Style<span className="required">*</span>
					</h3>

					<div className={`${styles.option_item_wrapper} ${styles.mt_20}`}>
						<div className={styles.radio_button_wrapper}>
							<GaawkRadioButton
								{...register("headerStyle")}
								label={<h4 style={{ margin: "0" }}>Stacked</h4>}
								id="STACKED"
								value="STACKED"
								name="headerStyle"
							/>
						</div>

						<div className={styles.info_text}>Image, title and description</div>

						<div className={styles.preview_wrapper}>
							<div className={styles.image_wrapper}>
								<img src={previewStacked} alt="" />
								<div className={styles.preview_title_wrapper}>
									<h3>title</h3>
								</div>
							</div>
							<p>Description of the service...</p>
						</div>
					</div>

					<div className={`${styles.option_item_wrapper} ${styles.mt_20}`}>
						<div className={styles.radio_button_wrapper}>
							<GaawkRadioButton
								{...register("headerStyle")}
								label={<h4 style={{ margin: "0" }}>Horizontal</h4>}
								id="HORIZONTAL"
								value="HORIZONTAL"
								name="headerStyle"
							/>
						</div>

						<div className={styles.info_text}>Image, title and description</div>

						<div className={`${styles.preview_wrapper} ${styles.horizontal}`}>
							<div className={styles.image_wrapper}>
								<img src={previewHorizontal} alt="" />
								<div className={styles.preview_title_wrapper}>
									<h3>title</h3>
								</div>
							</div>
							<p>Description of the service...</p>
						</div>
					</div>

					<div className={`${styles.option_item_wrapper} ${styles.mt_20}`}>
						<div className={styles.radio_button_wrapper}>
							<GaawkRadioButton
								{...register("headerStyle")}
								label={<h4 style={{ margin: "0" }}>Text</h4>}
								id="TEXT"
								value="TEXT"
								name="headerStyle"
							/>
						</div>

						<div className={styles.info_text}>Title and description only</div>

						<div className={styles.preview_wrapper}>
							<div className={`${styles.preview_title_wrapper} ${styles.text_only}`}>
								<h3>title</h3>
							</div>
							<p>Description of the service...</p>
						</div>
					</div>
				</div>

				{headerStyleWatcher !== "TEXT" && (
					<div className={styles.custom_upload_button}>
						<div className={styles.line_top} />
						<label className={styles.m_top}>
							Select size of header image
							<span className="required">*</span>
						</label>

						<div className={styles.options_container}>
							<div type="button" className={styles.option_item_wrapper}>
								<div className={styles.option_item_left}>
									<GaawkRadioButton
										{...register("imageSize")}
										centered={true}
										label={
											<div className={styles.radiolabel}>
												<label htmlFor="TALL">Tall</label>
												<p>1200x900</p>
											</div>
										}
										id="TALL"
										value="TALL"
										name="imageSize"
									/>
								</div>

								<img src={photoIconTall} alt="" />
							</div>

							<div type="button" className={styles.option_item_wrapper}>
								<div className={styles.option_item_left}>
									<GaawkRadioButton
										{...register("imageSize")}
										centered={true}
										label={
											<div className={styles.radiolabel}>
												<label htmlFor="STANDARD">Standard</label>
												<p>1200x600</p>
											</div>
										}
										id="STANDARD"
										value="STANDARD"
										name="imageSize"
									/>
								</div>

								<img src={photoIconStandard} alt="" />
							</div>

							<div type="button" className={styles.option_item_wrapper}>
								<div className={styles.option_item_left}>
									<GaawkRadioButton
										{...register("imageSize")}
										centered={true}
										label={
											<div className={styles.radiolabel}>
												<label htmlFor="SHORT">Short</label>
												<p>1200x300</p>
											</div>
										}
										id="SHORT"
										value="SHORT"
										name="imageSize"
									/>
								</div>

								<img src={photoIconShort} alt="" />
							</div>
						</div>

						{errors?.image?.message && (
							<div className={styles.form_input_wrapper} style={{ marginTop: "0" }}>
								<p className={styles.error_message}>{errors?.image?.message}</p>
							</div>
						)}

						{imageWatcher?.length > 0 &&
							((imageWatcher[0] instanceof Blob &&
								!(imageWatcher[0] instanceof File)) ||
								imageWatcher?.[0].url) && (
								<div className={styles.thumb}>
									<div
										className={styles.thumb_inner}
										style={{
											aspectRatio:
												sizeWatcher === "TALL"
													? 4 / 3
													: sizeWatcher === "STANDARD"
													? 2 / 1
													: 4 / 1,
										}}
									>
										<button
											className={styles.close_button}
											onClick={() => setValue("image", [])}
										>
											<img src={deleteIcon} alt={"delete"} />
										</button>

										<img
											src={
												imageWatcher[0] instanceof Blob &&
												!(imageWatcher[0] instanceof File)
													? objectUrls[0]
													: imageWatcher[0]?.url
											}
											alt=""
										/>
									</div>
								</div>
							)}

						<FileInput
							control={control}
							error={errors.image}
							name="image"
							required={true}
							// loadedFile={imageWatcher}
							loadedFile={tempImage}
							onCrop={(cropped) => {
								setValue("image", [cropped]);
							}}
							cropAspect={
								sizeWatcher === "TALL"
									? 4 / 3
									: sizeWatcher === "STANDARD"
									? 2 / 1
									: 4 / 1
							}
							onChange={(value) => {
								//TODO >> this should have a temp image same as in profile edit
								setTempImage([value.target.files[0]]);
								// setValue("image", [value.target.files[0]], {
								// 	shouldDirty: true,
								// });
							}}
							showThumbails={false}
						/>
					</div>
				)}
			</div>

			{headerStyleWatcher !== "TEXT" && (
				<div className={styles.section}>
					<h3 className={styles.title}>Mobile display</h3>

					<span className={styles.info_text}>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
						eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.
					</span>

					<div className={styles.react_switch}>
						<label>
							<Controller
								name="sameImageMobile"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Switch
										checked={value}
										onColor="#6cc5d1"
										offColor="#a6b1bc"
										handleDiameter={18}
										width={39}
										height={24}
										checkedIcon={false}
										uncheckedIcon={false}
										activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
										onChange={(val) => {
											onChange(val);
										}}
									/>
								)}
							/>
							<span>Use the same image as web display</span>
						</label>
					</div>

					{errors?.mobileImage?.message && (
						<div className={styles.form_input_wrapper} style={{ marginTop: "0" }}>
							<p className={styles.error_message}>{errors?.mobileImage?.message}</p>
						</div>
					)}

					{mobileImageWatcher?.length > 0 &&
						((mobileImageWatcher[0] instanceof Blob &&
							!(mobileImageWatcher[0] instanceof File)) ||
							mobileImageWatcher?.[0].url) && (
							<div className={styles.thumb}>
								<div className={styles.thumb_inner}>
									<button
										className={styles.close_button}
										onClick={() => setValue("mobileImage", [])}
									>
										<img src={deleteIcon} alt={"delete"} />
									</button>

									<img
										src={
											mobileImageWatcher[0] instanceof Blob &&
											!(mobileImageWatcher[0] instanceof File)
												? mobileObjectUrls[0]
												: mobileImageWatcher[0].url
										}
										alt=""
									/>
								</div>
							</div>
							// eslint-disable-next-line react/jsx-no-comment-textnodes
						)}

					<FileInput
						disabled={isSameImageMobile}
						control={control}
						error={errors.mobileImage}
						name="mobileImage"
						required={!isSameImageMobile}
						// loadedFile={mobileImageWatcher}
						loadedFile={tempImageMobile}
						onCrop={(cropped) => {
							setValue("mobileImage", [cropped]);
						}}
						acceptedFiles="image/png, image/jpg, image/jpeg"
						cropAspect={3 / 2}
						onChange={(value) => {
							setTempImageMobile([value.target.files[0]]);
							// setValue("mobileImage", [value.target.files[0]], {
							// 	shouldDirty: true,
							// });
						}}
						showThumbails={false}
					/>
				</div>
			)}

			<div className={`${styles.button_container} ${styles.padding}`}>
				<PrimaryButton
					className={styles.next_btn}
					text={`${service ? "update" : "create"} service`}
					// disabled={addServiceApi.loading || updateServiceApi.loading}
					isLoading={isLoading}
				/>
			</div>
		</form>
	);
};

export default Stage3;
