import { useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import MultiCheckbox from "../../../Utils/MultiCheckbox/MultiCheckbox";
import {
	languageFormatter,
	// defaultFormatter,
	// unduplicateValue,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "./Filters.module.css";
import { formatFiltersValues, genderTypes } from "components/Utils/General";
import { useDispatch, useSelector } from "react-redux";
import { updateJobsFilters } from "store/slices/filters";
import useDebounce from "hooks/useDebounce";
import jobApi from "api/job";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import useFilterAndSort from "components/Search/FilterTabs/useFilterAndSort";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
// import Tag from "../../../Utils/SubComs/Tags/Tag";
// import TagContainer from "../../../Utils/SubComs/Tags/TagContainer";
// import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
// import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
// import ethnicityApi from "../../../../api/ethnicity";
// import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
// import { languagesKeys } from "queryKeys/languages-key-factory";
// import useFetchLanguages from "hooks/useFetchLanguages";

// const itemsPerPage = 20;

const FILTERS_PAGE_SIZE = 100;

const CastingFilter = ({ languagesList, locationId }) =>
	//     {
	// 	onGenderChange,
	// 	gender,
	// 	onMinAgeChange,
	// 	onMaxAgeChange,
	// 	onCastingDateChange,
	// 	castingDate,
	// 	onEthnicityChange,
	// 	languages,
	// 	onAddLanguage,
	// 	onRemoveLanguage,
	// 	minAge,
	// 	maxAge,
	// 	ethnicity,
	// }
	{
		const dispatch = useDispatch();

		const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

		const storedJobsFilters = {
			...jobsFiltersObject,
			locationId,
		};

		const {
			castingCallRequest: {
				gender,
				minAge: storedMinAge = "",
				maxAge: storedMaxAge = "",
				languageIds = [],
				// ethnicityObject = null,
			},
		} = storedJobsFilters || {};

		// !===================

		const getJobsDynamicFilterApi = useApi(jobApi.jobsDynamicFilters, true, true);

		const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
			const [_, __, type, filters] = queryKey;
			const response = await getJobsDynamicFilterApi.request(
				type,
				pageParam,
				FILTERS_PAGE_SIZE,
				formatFiltersValues(filters)
			);
			return response.data;
		};

		const {
			items: dynamicLanguages,
			hasNextPage: hasNextPageLanguages,
			refetch: refetchLanguages,
			loadMore: loadMoreLanguages,
		} = useInfiniteScroll({
			queryKey: filterKeys.dynamicJobsFilter("languages", storedJobsFilters),
			queryFn: fetchDynamicFilters,
			pageSize: FILTERS_PAGE_SIZE,
			enabled: false,
		});

		const filteredLanguages = useFilterAndSort(
			languageIds,
			dynamicLanguages,
			languagesList,
			dynamicLanguages.length > 0,
			"code"
		);

		//! debouncing min age ==========================

		const [minAge, setMinAge] = useState(storedMinAge);
		const debouncedMinAge = useDebounce(minAge);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					castingCallRequest: {
						...storedJobsFilters.castingCallRequest,
						minAge: debouncedMinAge,
					},
				})
			);
		}, [debouncedMinAge]);

		//! debouncing max age ==========================

		const [maxAge, setMaxAge] = useState(storedMaxAge);
		const debouncedMaxAge = useDebounce(maxAge);

		useEffect(() => {
			dispatch(
				updateJobsFilters({
					castingCallRequest: {
						...storedJobsFilters.castingCallRequest,
						maxAge: debouncedMaxAge,
					},
				})
			);
		}, [debouncedMaxAge]);

		// !==================================

		// const getEthnicitiesApi = useApi(ethnicityApi.getEthnicities);
		// const [ethnicities, setEthnicities] = useState([]);

		// useEffect(() => {
		// 	getEthnicitiesApi.request();
		// }, []);

		// useEffect(() => {
		// 	if (
		// 		!getEthnicitiesApi.loading &&
		// 		getEthnicitiesApi.responseCode === 200 &&
		// 		getEthnicitiesApi.data.length > 0
		// 	) {
		// 		const response = getEthnicitiesApi.data;
		// 		setEthnicities(response.map((item) => defaultFormatter(item)));
		// 	}
		// }, [getEthnicitiesApi.data]);

		return (
			<div className={styles.container}>
				<div className={styles.section}>
					<MultiCheckbox
						title={"Gender"}
						type="radio"
						options={genderTypes}
						onSelect={(gender) =>
							dispatch(
								updateJobsFilters({
									castingCallRequest: {
										...storedJobsFilters.castingCallRequest,
										gender: gender[0].value,
									},
								})
							)
						}
						selected={[genderTypes.find((item) => item.value === gender)]}
						customStyle={styles.checkbox_labels}
					/>
				</div>

				<div className={styles.section}>
					<label>Age Range</label>
					<div className={styles.inline_input}>
						<div className={styles.form_input_wrapper}>
							<TextInput
								onChange={({ target }) => setMinAge(target.value)}
								value={minAge}
								type="number"
								placeholder="Minimum"
							/>
						</div>
						<div className={styles.form_input_wrapper}>
							<TextInput
								onChange={({ target }) => setMaxAge(target.value)}
								value={maxAge}
								type="number"
								placeholder="Maximum"
							/>
						</div>
					</div>
				</div>

				{/*
                    <div className={styles.section}>
                        <label>Ethnicity</label>
                        <CustomSelect
                            options={ethnicities}
                            onChange={onEthnicityChange}
                            value={ethnicity}
                            height="35px"
                            isClearable={true}
                        />
                    </div>
                */}

				<div className={styles.section}>
					{languagesList?.length > 0 && (
						<MultiCheckbox
							title={"Languages"}
							options={filteredLanguages.map((item) => languageFormatter(item))}
							onSelect={(languages) =>
								dispatch(
									updateJobsFilters({
										castingCallRequest: {
											...storedJobsFilters.castingCallRequest,
											languageIds: languages,
										},
									})
								)
							}
							selected={languageIds}
							// customStyle={styles.multicheckbox_container}
						/>
					)}

					{((dynamicLanguages?.length === 0 && languagesList?.length === 4) ||
						(dynamicLanguages?.length > 0 && hasNextPageLanguages)) && (
						<SeeMore
							className={styles.see_more}
							onClick={
								dynamicLanguages?.length === 0
									? refetchLanguages
									: loadMoreLanguages
							}
						/>
					)}
				</div>

				{/* <div className={styles.section}>
					<label>Start Date</label>
					<DayPicker
						onChange={onCastingDateChange}
						value={castingDate}
						isClearable={true}
					/>
				</div> */}
			</div>
		);
	};

export default CastingFilter;
