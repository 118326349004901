import styles from "./Filters.module.css";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import { defaultFormatter } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useApi from "../../../../hooks/useApi";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import { formatFiltersValues, frequencyTypes } from "../../../Utils/General";
import useCurrencies from "hooks/useCurrencies";
import { useEffect, useRef, useState } from "react";
import useDebounce from "hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { updateJobsFilters } from "store/slices/filters";
import jobApi from "api/job";
import { filterKeys } from "queryKeys/filters-key-factory";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useFilterAndSort from "components/Search/FilterTabs/useFilterAndSort";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
// import skillsApi from "../../../../api/skills";
// import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
// import { skillsKeys } from "queryKeys/skills-key-factory";

// const itemsPerPage = 20;
const FILTERS_PAGE_SIZE = 100;

const CompensationFilter = ({
	// onCompensationChange,
	// onFrequencyChange,
	// onCurrencyChange,
	// // onStatusChange,
	// // positionStatus,
	// skills,
	// onAddSkill,
	// onRemoveSkill,
	// compensation,
	// currency,
	// frequency,
	skillsList,
	locationId,
}) => {
	const skillRef = useRef();

	const dispatch = useDispatch();

	const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

	const storedJobsFilters = {
		...jobsFiltersObject,
		locationId,
	};

	const {
		compensation: storedCompensation,
		currencyId,
		frequency,
		skillIds,
	} = storedJobsFilters || {};

	// !========== currencies ==========

	const { data: currencies, isLoading: isLoadingCurrencies } = useCurrencies();

	//* below effect to dispatch default currencyId in jobFilters
	useEffect(() => {
		if (!currencyId && currencies?.length > 0 && !isLoadingCurrencies) {
			dispatch(
				updateJobsFilters({
					currencyId: currencies[0].uuid,
				})
			);
		}
	}, [currencies, isLoadingCurrencies, currencyId]);

	// !========== skills ==========

	// const getSkills = useApi(skillsApi.searchSkills, true, true);

	// const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
	// 	const [_, searchInput] = queryKey;

	// 	const response = await getSkills.request(pageParam, itemsPerPage, searchInput);
	// 	return response.data;
	// };

	const [enableSkillsFilters, setEnableSkillsFilters] = useState(false);

	const getJobsDynamicFilterApi = useApi(jobApi.jobsDynamicFilters, true, true);

	const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, type, filters] = queryKey;
		const response = await getJobsDynamicFilterApi.request(
			type,
			pageParam,
			FILTERS_PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: dynamicSkills,
		hasNextPage: hasNextPageSkills,
		refetch: refetchSkills,
		loadMore: loadMoreSkills,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicJobsFilter("skills", storedJobsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: enableSkillsFilters,
	});

	const filteredSkills = useFilterAndSort(
		skillIds,
		dynamicSkills,
		skillsList,
		dynamicSkills.length > 0
	);

	//! debouncing min compensation ==========================

	const [minCompensation, setMinCompensation] = useState(storedCompensation);
	const debouncedCompensation = useDebounce(minCompensation);

	useEffect(() => {
		dispatch(
			updateJobsFilters({
				compensation: debouncedCompensation,
			})
		);
	}, [debouncedCompensation]);

	return (
		<div className={styles.container}>
			{/* <MultiSelectInput
				queryName={skillsKeys.search}
				queryFn={fetchSkills}
				data={skills}
				itemsPerPage={itemsPerPage}
				formatter={defaultFormatter}
				label="Skills"
				onChange={onAddSkill}
				onRemoveItem={onRemoveSkill}
			/> */}

			{skillsList?.length > 0 && (
				<div className={styles.section}>
					<MultiCheckbox
						ref={skillRef}
						title={"Skills"}
						options={filteredSkills.map((item) => defaultFormatter(item))}
						onSelect={(skills) => {
							dispatch(
								updateJobsFilters({
									skillIds: skills,
								})
							);
						}}
						selected={skillIds}
						// perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>

					{((dynamicSkills?.length === 0 && skillsList.length === 4) ||
						(dynamicSkills?.length > 0 && hasNextPageSkills)) && (
						<SeeMore
							onClick={() => {
								if (dynamicSkills?.length === 0) {
									refetchSkills();
								} else {
									loadMoreSkills();
								}

								setEnableSkillsFilters(true);
							}}
							className={styles.see_more}
						/>
					)}
				</div>
			)}

			<div className={styles.form_input_wrapper}>
				<label>Compensation</label>
				<TextInput
					onChange={({ target }) => setMinCompensation(target.value)}
					value={minCompensation}
					type="number"
					placeholder="Minimum"
				/>
			</div>

			<div className={`${styles.section} ${styles.inline_input}`}>
				<div className={styles.item}>
					<label>Currency</label>
					{/* <CustomSelect
						isLoading={isLoadingCurrencies}
						options={currencies?.map((currency) => ({
							label: currency.name,
							value: currency.uuid,
						}))}
						placeholder="Select"
						height="35px"
						isClearable={true}
						onChange={onCurrencyChange}
						value={currency}
					/> */}
					<CustomSelect
						isLoading={isLoadingCurrencies}
						options={currencies?.map((currency) => defaultFormatter(currency))}
						placeholder="Select"
						height="35px"
						// isClearable={true}
						onChange={(currency) =>
							dispatch(
								updateJobsFilters({
									currencyId: currency ? currency.value : "",
								})
							)
						}
						value={currencies
							?.map((currency) => defaultFormatter(currency))
							.find((item) => item.value === currencyId)}
					/>
				</div>
				<div className={styles.item}>
					<label>Payment Frequency</label>
					{/* <CustomSelect
						options={frequencyTypes}
						placeholder="Select"
						height="35px"
						isClearable={true}
						onChange={onFrequencyChange}
						value={frequency}
					/> */}
					<CustomSelect
						options={frequencyTypes}
						placeholder="Select"
						height="35px"
						// isClearable={true}
						onChange={(freq) =>
							dispatch(
								updateJobsFilters({
									frequency: freq ? freq.value : "",
								})
							)
						}
						value={frequencyTypes.find((item) => item.value === frequency)}
					/>
				</div>
			</div>

			{/* <div className={styles.section}>
                <label>Status</label>
                <div className={styles.switch_wrapper}>
                    <Switch
                        checked={positionStatus}
                        onColor="#6cc5d1"
                        offColor="#a6b1bc"
                        handleDiameter={18}
                        width={39}
                        height={24}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        boxShadow={"none"}
                        activeBoxShadow={"none"}
                        onChange={onStatusChange}
                    />
                    <span>Show closed jobs</span>
                </div>
            </div> */}
		</div>
	);
};

export default CompensationFilter;
