import { useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useApi from "hooks/useApi";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import useUserLocation from "hooks/useUserLocation";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useMemo } from "react";
import NewJobCard from "../JobsComponents/NewJobCard/NewJobCard";
import styles from "./Jobs.module.css";

const PAGE_SIZE = 40;

const Jobs = () => {
	const queryClient = useQueryClient();

	const { userCoordinates } = useUserLocation();

	// ! ========================== RECOMMENDED JOBS LOGIC ==========================
	const getRecommendedJobsApi = useApi(jobApi.getRecommendedJobs, true, true);

	const fetchRecommendedJobs = async ({ pageParam = 0, queryKey }) => {
		const [_, __, ___, userCoordinates] = queryKey;
		const response = await getRecommendedJobsApi.request(
			pageParam,
			PAGE_SIZE,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const { data, items, isFetching, viewRef, isFetchingNextPage, isLoading } = useInfiniteScroll({
		queryKey: jobsKeys.recommended(userCoordinates),
		queryFn: fetchRecommendedJobs,
		pageSize: PAGE_SIZE,
	});

	const hasReco = items?.length > 0;

	const recoList = useMemo(
		() =>
			items?.map((job, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<div key={job.uuid} ref={isLastItem ? viewRef : null}>
						<NewJobCard
							data={job}
							onToggleSave={() =>
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
									isReco: true,
								})
							}
						/>
						{isLastItem && isFetchingNextPage && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</div>
				);
			}),
		[items, viewRef]
	);

	// ! ========================== NEARBY JOBS LOGIC ==========================

	const getNearbyJobsApi = useApi(jobApi.getNearbyJobs, true, true);

	const fetchNearby = async ({ pageParam = 0, queryKey }) => {
		const [_, __, ___, userCoordinates] = queryKey;
		const response = await getNearbyJobsApi.request(
			pageParam,
			PAGE_SIZE,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const {
		items: nearbyItems,
		viewRef: nearViewRef,
		isFetchingNextPage: isFetchingNextPageNearby,
		isLoading: isLoadingNearby,
		fetchStatus: fetchStatusNearby,
	} = useInfiniteScroll({
		queryKey: jobsKeys.nearby(userCoordinates),
		queryFn: fetchNearby,
		pageSize: PAGE_SIZE,
		enabled: !isFetching && data?.pages?.[0].length === 0,
	});

	const hasNearby = nearbyItems?.length > 0;

	const nearbyList = useMemo(
		() =>
			nearbyItems?.map((job, index) => {
				const isLastItem = index === nearbyItems.length - 1;
				return (
					<div key={job.uuid} ref={isLastItem ? nearViewRef : null}>
						<NewJobCard
							data={job}
							onToggleSave={() =>
								toggleSave({
									jobId: job.uuid,
									state: !job.savedJob,
									isReco: false,
								})
							}
						/>
						{isLastItem && isFetchingNextPageNearby && (
							<div className={styles.loading_item_container}>
								<JobLoader />
							</div>
						)}
					</div>
				);
			}),
		[nearbyItems, nearViewRef]
	);

	// ! =======================================================================

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, (_, variables) => {
		const { isReco, jobId } = variables;

		queryClient.setQueryData(
			isReco ? jobsKeys.recommended(userCoordinates) : jobsKeys.nearby(userCoordinates),
			(oldData) => {
				return {
					...oldData,
					pages: oldData.pages.map((page) =>
						page.map((job) =>
							job.uuid === jobId ? { ...job, savedJob: !job.savedJob } : job
						)
					),
				};
			}
		);
	});

	if (isLoading || (isLoadingNearby && fetchStatusNearby !== "idle"))
		return (
			<div className={styles.loader_container}>
				<div>
					<JobLoader />
				</div>
				<div>
					<JobLoader />
				</div>
				<div>
					<JobLoader />
				</div>
			</div>
		);

	return (
		<>
			<div className={styles.recommended_jobs}>
				{hasReco && (
					<>
						<h3 className={styles.title}>Recommended Jobs</h3>
						<div className={styles.jobs_wrapper}>{recoList}</div>
					</>
				)}

				{hasNearby && (
					<>
						<h3 className={styles.title}>Jobs Nearby</h3>
						<div className={styles.jobs_wrapper}>{nearbyList}</div>
					</>
				)}

				<NoResults text={"No jobs for the moment"} visible={!hasReco && !hasNearby} />
			</div>
		</>
	);
};

export default Jobs;
