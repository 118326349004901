export const jobsKeys = {
	all: ["jobs"],

	landing: () => [...jobsKeys.all, "landing"],

	lists: () => [...jobsKeys.all, "listJobs"],

	details: () => [...jobsKeys.all, "details"],

	detail: (jobId) => [...jobsKeys.details(), jobId],

	recommended: (userCoordinates) => [...jobsKeys.lists(), "recommended", userCoordinates],

	nearby: (userCoordinates) => [...jobsKeys.lists(), "nearby", userCoordinates],

	saved: (jobType) => [...jobsKeys.lists(), "saved", jobType],

	applied: (jobType) => [...jobsKeys.lists(), "applied", jobType],

	jobsByCompany: (companyId, jobType, closed, excludeId = "", projectId = "") => [
		...jobsKeys.lists(),
		companyId,
		jobType,
		closed,
		excludeId,
		projectId,
	],

	similarJobs: (jobId) => [...jobsKeys.lists(), "similar", jobId],

	jobApplicants: (jobId, statusId) => [...jobsKeys.all, "applicants", jobId, statusId],

	applicationsCount: (jobId) => [...jobsKeys.all, "applicationsCount", jobId],

	applicant: (applicantId) => [...jobsKeys.all, "applicant", applicantId],
};
